import { default as _91_46_46_46slug_932RFvMi2aHBMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93zOy17AipmSMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/blogg/[...slug].vue?macro=true";
import { default as indexLnF2YOkUVjMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/blogg/index.vue?macro=true";
import { default as indexIEpi4J8nfqMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/checkout/index.vue?macro=true";
import { default as paymentjIm0UtyDadMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/checkout/payment.vue?macro=true";
import { default as indexGEL1odEZmDMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/checkout/thank-you/index.vue?macro=true";
import { default as index2GKRJSPlA7Meta } from "/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/collections/index.vue?macro=true";
import { default as _91slug_93ki3BSxglTGMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/faq/[slug].vue?macro=true";
import { default as indexBhBGcxJdUjMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/hillceramic-rabattkoder/index.vue?macro=true";
import { default as indexHR4jihf2mXMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/index.vue?macro=true";
import { default as indexb4cOH4qF15Meta } from "/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/login/index.vue?macro=true";
import { default as _91id_93rJjyhAt3ebMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/qr-pr/[id].vue?macro=true";
import { default as _91_46_46_46slug_93esXtG406fSMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/shop/[...slug].vue?macro=true";
import { default as indexvCmYTrF91OMeta } from "/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/track/index.vue?macro=true";
export default [
  {
    name: "slug___sv",
    path: "/:slug(.*)*",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/[...slug].vue")
  },
  {
    name: "blogg-slug___sv",
    path: "/blogg/:slug(.*)*",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/blogg/[...slug].vue")
  },
  {
    name: "blogg___sv",
    path: "/blogg",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/blogg/index.vue")
  },
  {
    name: "checkout___sv",
    path: "/checkout",
    meta: indexIEpi4J8nfqMeta || {},
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___sv",
    path: "/checkout/payment",
    meta: paymentjIm0UtyDadMeta || {},
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/checkout/payment.vue")
  },
  {
    name: "checkout-thank-you___sv",
    path: "/checkout/thank-you",
    meta: indexGEL1odEZmDMeta || {},
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/checkout/thank-you/index.vue")
  },
  {
    name: "collections___sv",
    path: "/kollektioner",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/collections/index.vue")
  },
  {
    name: "faq-slug___sv",
    path: "/faq/:slug()",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/faq/[slug].vue")
  },
  {
    name: "hillceramic-rabattkoder___sv",
    path: "/hillceramic-rabattkoder",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/hillceramic-rabattkoder/index.vue")
  },
  {
    name: "index___sv",
    path: "/",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/index.vue")
  },
  {
    name: "login___sv",
    path: "/login",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/login/index.vue")
  },
  {
    name: "qr-pr-id___sv",
    path: "/qr-pr/:id()",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/qr-pr/[id].vue")
  },
  {
    name: "shop-slug___sv",
    path: "/shop/:slug(.*)*",
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/shop/[...slug].vue")
  },
  {
    name: "track___sv",
    path: "/track",
    meta: indexvCmYTrF91OMeta || {},
    component: () => import("/var/www/projects/dev2.hillceramic.se/releases/20241022063358Z/pages/track/index.vue")
  }
]